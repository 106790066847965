declare global {
    interface String {
        slugToText(): string;
        TrimTrailingSlash(): string;
        textToSlug(): string
    }
}

String.prototype.slugToText = function () {
    const value = String(this)
    return value.split('-').join(' ').replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase());
};

String.prototype.TrimTrailingSlash = function () {
    const value = String(this)
    return value.replace(/\/+$/, '');
};

String.prototype.textToSlug = function () {
    const value = String(this)
    return value.split(' ').join('-').toLowerCase();
};

export { }