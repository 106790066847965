import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './Header.module.css'
import headerData from "../../../data/header.json"
import { NavLink } from '../ui';
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { useRouter } from 'next/router';
type props = {
    menu: Boolean,
    setMenu: Dispatch<SetStateAction<boolean>>;
}

export function MobileNav({ menu, setMenu }: props) {
    const [active, setActive] = useState(-1)
    const router = useRouter()
    const { props: { siteUrl } } = useGlobalContext()
    const handleClick = (e: any, index: number) => {
        e.preventDefault();
        if (active == index) {
            setActive(-1)
        } else {
            setActive(index)
        }
    }

    useEffect(() => {
        setMenu(false)
    }, [router])
    return <div className={menu ? `${styles.mobiletabs} ${styles.active}` : styles.mobiletabs}>
        <div className={`${styles.overlay} ${styles.left}`}>
            <div className={styles.mobilenavitems}>
                <div className={styles.mobileHamburgerclose} onClick={()=>setMenu(false)}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' />
                    </svg>
                </div>
                <ul>
                    {headerData.map((f, idx) =>
                        <li className={active === idx ? styles.active : ""} key={idx}>
                            <div className={styles.listHead} onClick={(e) => handleClick(e, idx)}>
                                {f.pageUrl ? <NavLink href={`${siteUrl}/${f.pageUrl}`}><p className="font16 textfff">{f.pageName}</p></NavLink> :
                                    <p className="font16 textfff">{f.pageName}</p>}
                                {f.child.length != 0 && <span className={`${styles.arw} arw`}></span>}
                            </div>
                            <ul className={styles.subnavitems} onClick={() => setMenu(false)}>
                                {f.child.map((m, idx) =>
                                    <li key={idx}> <NavLink href={m.absoluteUrl == 1 ? m.pageUrl : `${siteUrl}/${m.pageUrl}`} className="font14  textb5b5b5 textUpper">{m.pageName}</NavLink></li>
                                )}
                            </ul>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
}