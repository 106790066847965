import { TermAndCopyright } from '@/components/homePage/TermAndCopyright';
import Image from 'next/image';
import styles from './Footer.module.css'
import footerData from '../../../data/footer.json'
import { Input, NavLink } from '../ui';
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { useState } from 'react';
import { validationPattern } from '@/libs/constants/validationPatten';
export function Footer() {
    const [active, setActive] = useState(-1)
    const { props: { siteUrl } } = useGlobalContext()
    const handleClick = (e: any, index: number) => {
        e.preventDefault();
        if (active == index) {
            setActive(-1)
        } else {
            setActive(index)
        }
    }
    return <>
        <footer className={styles.footer}>
            <div className="container">
                <div className="logo mb40">
                    <Image src="/assets/images/logo.png" alt="" width={200} height={43} />
                </div>
                <div className={styles.menuGrid}>
                    {footerData.map((f, idx) =>
                        <div className={active === idx ? `${styles.griditem} ${styles.active}` : styles.griditem} key={idx}>
                            <div className={styles.footerlinktitle} onClick={(e) => handleClick(e, idx)}>
                                {f.pageUrl ? <NavLink href={`${siteUrl}/${f.pageUrl}`}> <p className={`textUpper font20 fontsemiBold ${styles.itemHeading}`}>{f.pageName}</p></NavLink> :
                                    <p className={`textUpper font20 fontsemiBold ${styles.itemHeading}`}>{f.pageName}</p>}
                                {f.child && <span className={`${styles.arw} arw`}></span>}
                            </div>
                            <ul className={`${styles.sublowercateogry}`}>
                                {f.child.map((m, idx) =>
                                    <li key={idx}>{m.pageName === "MEDIA" ? <NavLink href={m.absoluteUrl == 1 ? m.pageUrl : `${siteUrl}/${m.pageUrl}`} target='_blank' className="fontThin text666 lineHeight32 font16 fontsm14 ">{m.pageName}</NavLink>:<NavLink href={m.absoluteUrl == 1 ? m.pageUrl : `${siteUrl}/${m.pageUrl}`} className="fontThin text666 lineHeight32 font16 fontsm14 ">{m.pageName}</NavLink>}</li>)}
                            </ul>
                        </div>)}
                    <div className={styles.griditem}>
                        <div className={`${styles.footerlinktitle} mb20`}>
                            <p className={`textUpper font20 fontsemiBold ${styles.itemHeading}`}>contact</p>
                        </div>
                        {/* <ul className="socialIcons">
                        <li><img src="/assets/images/instagramlogo.png" alt="" srcset=""></li>
                        <li><img src="/assets/images/facebookicon.svg" alt="" srcset=""></li>
                        <li><img src="/assets/images/youtubeicon.svg" alt="" srcset=""></li>
                    </ul>  */}
                        <p className="font16 fontThin text666"><a className='text666' href='mailto:info@nirvasa.com'>email: info@nirvasa.com</a></p>
                        <p className="font16 fontThin text666 mb25"><a className='text666' href='tel:01244811144'>helpline: 0124 481 1144</a></p>
                        <p className="font20 textupper itemHeading textUpper fontsemiBold">IT&apos;S EASY TO CONNECT AND BE HEALTHY</p>
                        <div className={styles.socialInputsFields}>
                            <div className={styles.Inputs}>
                                <Input type="text" name="name" id="name" pattern={validationPattern.Character} placeholder="YOUR NAME" />
                            </div>
                            <div className={styles.Inputs}>
                                <input type="text" name="name" id="email" pattern={validationPattern.Email} placeholder="EMAIL ADDRESS" />
                            </div>
                            <button id="btnadd" className="btn btn-secondry font14 textupper">SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <TermAndCopyright />
    </>
}