import styles from './TermAndCopyright.module.css'
import { useGlobalContext } from '@/libs/context/GlobalContext'
export function TermAndCopyright() {
    const { props: { siteUrl } } = useGlobalContext()
    return <section className="termandCopyright">
        <div className="container">
            <div className={styles.copyrights}>
                <ul>
                    <li><a className="font16 fontsm10 text666 fontThin lineHeight30" href={`${siteUrl}/terms-and-conditions`}>Terms Of Use </a></li>
                    <li><a className="font16 fontsm10 text666 fontThin lineHeight30" href={`${siteUrl}/privacy-policy`}> Privacy </a></li>
                    <li><a className="font16 fontsm10 text666 fontThin lineHeight30" href={`${siteUrl}/shipping-policy`}> Shipping </a></li>
                    <li><a className="font16 fontsm10 text666 fontThin lineHeight30" href={`${siteUrl}/refund-and-cancellation-policy`}>Refund & Cancellation</a></li>
                </ul>
                <p className="font16  fontsm10 text666 fontThin textcenter mb10">2023 nirvasa.com</p>
            </div>
        </div>
    </section>
}