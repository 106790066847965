import Image from 'next/image'
import styles from './Header.module.css'
import { useGlobalContext } from '@/libs/context/GlobalContext'
import { MobileNav } from './MobileNav'
import { WebNav } from './WebNav'
import { LeadForm } from '../LeadForm/LeadForm'
import { useState } from 'react'
import { NavLink } from '../ui'
import logo from '../../../public/assets/images/logo.png'
export function Header() {
    const [menu, setMenu] = useState(false)
    const { props: { isMobileView } } = useGlobalContext()
    return <header className={styles.header}>
        <div className="logoBoxandModal">
            <div className={styles.mobileHamburger} onClick={() => setMenu(true)}>
                <button className={`${styles.btnmenu} d-lg-none`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            {/* <LeadForm/> */}
            <NavLink href='/' exact>
                <Image
                    className="imgResponsive"
                    src={logo}
                    height={43}
                    width={isMobileView ? 150 : 200}
                    quality={100}
                    alt="logo" />
            </NavLink>
        </div>
        <div className="allNavs">
            {isMobileView ? <MobileNav menu={menu} setMenu={setMenu} /> : <WebNav />}
        </div>
    </header>
}