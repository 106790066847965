import React, {InputHTMLAttributes, KeyboardEvent} from "react";


export const Input = (props:InputHTMLAttributes<HTMLInputElement>) => {    

      const validateInput = (event: KeyboardEvent<HTMLInputElement>) => {
        const target=event.target as HTMLInputElement;
        const keyValue = event.key
        if(target && target.pattern && !(new RegExp(target.pattern).test(keyValue)))
            event.preventDefault();
         return;
      };

    return ( 
        <input 
            id= { props.id }
            type = { props.type }
            name = { props.name }
            onKeyUp = { props.onKeyUp }
            onBlur={props.onBlur}
            onKeyDown = { props.onKeyDown }
            pattern = {props.pattern}
            onKeyPress={validateInput}
            onInput = {validateInput }
            onPaste = {(e:any)=>validateInput(e)}
            className = { props.className }
            value = { props.value }
            autoComplete="OFF"
            disabled={props.disabled}
            //onBlur= {props.handleBlur? props.handleBlur :null }
            placeholder = { props.placeholder }
            required={props.required}
            maxLength={props.maxLength}
            onChange = {props.onChange} 
            tabIndex = {props.tabIndex}
            />
    );
};
