import { Layout } from '@/components/layout'
import "../public/assets/css/global.css";
import '@/libs/helpers/extensionMethods';
import { GlobalContext } from '@/libs/context/GlobalContext';
import localFont from 'next/font/local'
type props = {
  Component: any;
  pageProps: any;
  pageURL: string;
  isMobileView: boolean;
  siteUrl: string
}
const poppins = localFont({
  src: [
    {
      path: '../public/assets/fonts/Poppins-ExtraLight.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../public/assets/fonts/Poppins-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/assets/fonts/Poppins-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/assets/fonts/Poppins-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/assets/fonts/Poppins-Bold.ttf',
      weight: 'bold',
      style: 'normal',
    },
  ], display: 'swap'
})
function MyApp({ Component, pageProps, pageURL, isMobileView, siteUrl }: props) {
  return (<>
    <GlobalContext.Provider value={{
      props: {
        pageURL,
        isMobileView,
        siteUrl
      }
    }}>
      <main className={poppins.className}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </main>
    </GlobalContext.Provider>
  </>)
}
MyApp.getInitialProps = async (pageContext: any) => {
  const ctx = pageContext.ctx;
  const siteUrl = process.env.SITE_URL ? process.env.SITE_URL : `${ctx.req?.headers['x-forwarded-proto']}://${ctx.req.headers.host}`
  let isMobileView = false;
  const cfdevicetype: string = ctx.req && ctx.req?.headers['cf-device-type']?.toString() || "";
  if (cfdevicetype && ctx.req?.headers['cf-device-type'])
    isMobileView = cfdevicetype.toLowerCase() == "mobile";
  else {
    const userAgent = ctx.req && ctx.req?.headers['user-agent'] || navigator.userAgent;
    isMobileView = /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera\ mini|avantgo|mobilesafari|docomo|KAIOS)/i.test(userAgent);
  }
  return {
    pageURL: ctx.asPath,
    isMobileView,
    siteUrl: siteUrl
  }
};
export default MyApp