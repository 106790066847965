import { createContext, useContext } from 'react';
import { IGlobalContent } from '../../interfaces';

export const GlobalContext = createContext<IGlobalContent>({
  props : {
    pageURL:"",
    isMobileView:false,
    siteUrl:""
  }
})
export const useGlobalContext = () => useContext(GlobalContext)