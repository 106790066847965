import { Header, Footer } from "../shared";
export function Layout(props:{ children:any }) {
  return (
    <>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
