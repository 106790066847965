import { useGlobalContext } from '@/libs/context/GlobalContext'
import styles from './Header.module.css'
import headerData from '../../../data/header.json';
import { NavLink } from '../ui';
export function WebNav() {
    const { props: { siteUrl } } = useGlobalContext()
    return <div className={styles.webNavs}>
        <ul className={styles.navItems}>
            {headerData.map((f, idx) =>
                <li key={idx}>
                    {f.pageUrl ?
                        <p><NavLink href={`${siteUrl}/${f.pageUrl}`} className="font14 textUpper text333">{f.pageName}</NavLink></p>
                        : <p className="font14 textUpper text333">{f.pageName}</p>}
                    {f.child.length != 0
                        && <> <span className={`${styles.arw} arw arwdown`}></span>
                            <ul className={styles.subCategoryMenu}>
                                {f.child.map((m, idx) =>
                                    <li key={idx}> <NavLink href={m.absoluteUrl == 1 ? m.pageUrl : `${siteUrl}/${m.pageUrl}`} className="font14 text333">{m.pageName}</NavLink></li>
                                )}
                            </ul>
                        </>}
                </li>)}
        </ul>
    </div>
}