import PropTypes from "prop-types";
import Link from 'next/link';

export { NavLink };

NavLink.propTypes = {
    href: PropTypes.string.isRequired,
    exact: PropTypes.bool,
};

NavLink.defaultProps = {
    exact: false,
};

function NavLink({ children, href, exact, ...props }: {
    [x: string]: any,
    children?: any,
    href: string,
    exact?: boolean
}): JSX.Element {
    return (
        <Link href={href} legacyBehavior={true}>
            <a href={href} {...props}>{children}</a>
        </Link>
    );
}

